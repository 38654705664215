<template>
  <div id="formulario" class="center">
    <div class="d-flex flex-row justify-content-center align-items-center pb-2">
      <span class="font-weight-bold" style="color: #003159; font-size: 1rem">{{ this.infostep }}</span>
    </div>
    <div class="">
      <div id="pelotitas" class="d-flex flex-row justify-content-center align-items-center">
        <div>
          <img width="20" height="20" :src="publicPath + 'check-' + checkcolor1 + '-1.svg'" alt="check1" />
          <img width="30" height="15" :src="publicPath + 'barra-' + barracolor1 + '.svg'" alt="check1" />
          <img width="20" height="20" :src="publicPath + 'check-' + checkcolor2 + '.svg'" alt="check2" />
          <img width="30" height="15" :src="publicPath + 'barra-' + barracolor2 + '.svg'" alt="check1" />
          <img width="20" height="20" :src="publicPath + 'check-' + checkcolor3 + '.svg'" alt="check3" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { mapState } from "vuex";

export default {
  name: "PelotitasPasosApi",
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  props: {
    infostep: String,
  },
  store,
  computed: mapState({
    formulario: (state) => state.formulario,
    checkcolor1: (state) => state.checkcolor1,
    checkcolor2: (state) => state.checkcolor2,
    checkcolor3: (state) => state.checkcolor3,
    barracolor1: (state) => state.barracolor1,
    barracolor2: (state) => state.barracolor2,
  }),
  methods: {},
  mounted() {},
};
</script>

<style lang="scss">
@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";

.loader {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .text-loader {
    text-align: center;
    margin-bottom: 14px;
    font-size: 19px;
  }
}

@include media-breakpoint-between(xs, xl) {
  #formulario {
    width: auto;
    min-height: 10px;
    min-width: 350px;
    height: auto;
    border-radius: 0px;
  }
}
</style>
