<template>
  <div v-if="preload">
    <Loading />
  </div>
  <div v-if="!preload">
    <div v-if="formulario.step != 10" class="bg d-flex row justify-content-center align-items-center">
      <div class="container pt-4 pb-4">
        <!-- tenemos coche pero no tasación -->
        <h2 id="aceptada" v-if="solicitud.coche_ganvam == 0" class="text-center font-weight-bolder pb-3 mt-4">¡Enhorabuena!</h2>
        <h4 class="text-center font-weight-bolder pb-3 preaprobado">Tu solicitud ha sido aceptada.</h4>
        <!-- no tenemos coche ni tasación -->
        <h2 v-if="solicitud == undefined && solicitud.coche_ganvam == undefined" class="soli-aceptada">
          <strong>Tu solicitud <span class="orange">ha sido aceptada.</span> Nos pondremos en contacto para confirmar los datos y enviarte una propuesta.</strong>
        </h2>
        <!-- tenemos coche y tasación -->
        <h2 v-if="solicitud != undefined && solicitud.coche_ganvam != undefined && maxPrestar != ''" class="text-center font-weight-bolder pb-3">¡Enhorabuena!</h2>
        <h4 v-if="solicitud != undefined && solicitud.coche_ganvam != undefined && maxPrestar != ''" class="text-center font-weight-bold preaprobado">Tienes un préstamo <br class="d-md-none d-lg-none d-xl-none"> preaprobado de</h4>
      </div>
    </div>
    <div v-if="solicitud.coche_ganvam == 0" id="ganvam-cero" style="">
      <p><strong style="font-weight: bolder;">Con tu coche {{ solicitud.marca_modelo + " " + solicitud.coche_matriculacion }}</strong><br>
       (Sujeto a estudio crediticio)</p>
      <p style="color: #004680; font-size: 18px; font-weight: bold; margin-top: 50px;">Continúa por una de las siguientes opciones para recibir tu propuesta de préstamo.</p>  
    </div>
    <div v-if="formulario.step != 10 && solicitud.coche_ganvam != 0" class="bg-money d-flex row justify-content-center align-items-center">
      <!-- tenemos coche y tasación -->
      <div v-if="solicitud != undefined && solicitud.coche_ganvam != undefined && maxPrestar != ''" class="container pt-3 pb-3">
        <h2 class="text-center font-weight-bold primera-azul">
          <strong>{{ maxPrestar }}€</strong>
        </h2>
      </div>
    </div>

    <div v-if="formulario.step != 10" class="d-flex row justify-content-center align-items-center">
      <!-- tenemos coche y tasación -->
      <div v-if="solicitud != undefined && solicitud.coche_ganvam != undefined && maxPrestar != ''" class="container justify-content-center pt-3">
        <h2 class="text-center font-weight-bolder cuarta mt-2">
          <strong>Con tu coche {{ solicitud.marca_modelo + " " + solicitud.coche_matriculacion }}</strong>
        </h2>
        <p class="text-center quinta">(Sujeto a estudio crediticio)</p>
        <p class="text-center sexta mt-5">¡Consigue tu préstamo hoy!</p>
      </div>
    </div>

    <!-- Botones Whatssap, Docu, Llamar -->
    <div v-if="formulario.step == 5" class="container-fluid pt-3">
      <div class="row justify-content-center">
        <div class="col-xs-12 col-sm-12 col-md-10 col-lg-7 col-xl-9 text-center">
          <NuevoEmailRespuesta :solicitud="solicitud" />
        </div>
      </div>
    </div>
    <StepUltimasPreguntas v-if="formulario.step == 10" :solicitud="solicitud" :propuesta="maxPrestar" />
    <div v-if="formulario.step <= 7">
      <div class="row justify-content-center">
        <div class="col-xs-12 col-sm-12 col-md-11 col-lg-11 col-xl-9 text-center">
          <NuevoSectionDocumentacion></NuevoSectionDocumentacion>
        </div>
      </div>
    </div>

    <!-- input hidden para campañas de marketing que usan google tags y requieren de este elemento -->
    <div>
      <input v-if="this.formulario && this.formulario.email" id="email"  type="hidden" :value="this.formulario.email">
    </div>
  </div>
</template>

<script>
import axios from "axios";
import store from "@/store";
import { mapState } from "vuex";

import NuevoEmailRespuesta from "@/components/NuevoFormulario/NuevoEmailRespuesta.vue";
import NuevoSectionDocumentacion from "@/components/NuevoFormulario/NuevoSectionDocumentacion.vue";
import StepUltimasPreguntas from "@/components/Formulario/StepUltimasPreguntas.vue";
import Loading from "./Loading.vue";

export default {
  name: "NuevoFormularioSave",
  components: {
    NuevoEmailRespuesta,
    NuevoSectionDocumentacion,
    StepUltimasPreguntas,
    Loading,
  },
  store,
  computed: mapState({
    step: (state) => state.formulario.step,
    api_token: (state) => state.api_token,
    formulario: (state) => state.formulario,
    data_matricula: (state) => state.formulario.data_matricula,
    solicitud: (state) => state.solicitud,
    preload: (state) => state.preload,
  }),
  data: function () {
    return {
      codigo: "",
      maxPrestar: "",
      windowWidth: window.innerWidth,
    };
  },
  methods: {
    setStep: function (step) {
      store.commit("setStep", step);
    },

    calcMaxPrestar: function () {
      axios.post(process.env.VUE_APP_AXIOS_BASE_URL + "get/propuesta/" + this.solicitud.id).then((data) => {
        if (data.data) {
          if (data.data.prestamo_cliente > 0 && this.solicitud.coche_necesita > 0) {
            this.maxPrestar = this.solicitud.coche_necesita > data.data.prestamo_cliente ? data.data.prestamo_cliente : this.solicitud.coche_necesita;
            store.commit("setMaximoPrestamo", this.maxPrestar);
          }
        }
      });
    },
    comprobarCampos: function () {
      return this.solicitud.coche_matricula == null || this.solicitud.coche_financiacion == null || this.solicitud.coche_titular == null || this.solicitud.ingresos == null;
    },
    comprobarCarfax: function () {
      return this.solicitud.coche_marca == null || this.solicitud.coche_combustible == null || this.solicitud.coche_puertas == null || this.solicitud.coche_cambio_marchas == null;
    },
    getWindowWidth() {
      this.windowWidth = window.innerWidth;
    }
  },
  created: function () {
    window.dataLayer = window.dataLayer || []; //LLAMADA GOOGLE TAG

    store.commit("setPreload", true);
  },
  mounted: function () {
    // Si tenemos la propiedad email en el formulario lanzamos el evento de google tag para que capture el email y llevado a la campaña de google ads para su analisis desde Marketing
    if (this.formulario && this.formulario.email) {
            window.dataLayer.push({
                'event': 'solicitud_aceptada'
            });
        }
    this.setStep(5);
    this.codigo = this.$route.params.codigo;
    // Si no viene código, redirigir al primer paso
    const menu = document.querySelector('#navigation_menu');
    menu.style.display = 'block';
    window.addEventListener('resize', this.getWindowWidth);
    if (isNaN(this.codigo) || this.codigo == "") {
      this.setStep(1);
      this.$router.push({ path: "/", hash: null });
    } else {
      // Buscar id solicitud
      var idSolicitud = this.codigo.slice(14);
      store.commit("setTokenEnlace", this.codigo);
      store.commit("setIdSolicitud", idSolicitud);
      axios
        .get(process.env.VUE_APP_AXIOS_BASE_URL + "get/solicitud/web/" + this.codigo)
        .then((resp) => {
          let solicitud = resp.data.data;
          store.commit("setSolicitud", solicitud);
          store.commit("setAfiliado", solicitud.web);
          this.calcMaxPrestar();

          if (solicitud.numeroestado >= 9000) {
            this.setStep(1);
            if (this.formulario && this.formulario.email) {
                window.dataLayer.push({
                    'event': 'solicitud_denegada',
                    'mail': this.formulario.email
                });
            }
            this.$router.push({
              path: "/solicitud/denegada/" + this.codigo,
              hash: null,
            });
          } else if (this.comprobarCampos()) {
            //#NF EN CASO DE NO TENER MARCADO ALGUNO DE ESTOS DATOS LO MANDAMOS A API
            this.setStep(10);
          } else if (this.step < 5) {
            this.setStep(5);
          } else if (this.comprobarCarfax()) {
            //#NF EN CASO DE FALTAR ALGUN DATO DEL COCHE (MARCA, MODELO, ETC. ) LO ENVIAMOS AL FORMULARIO DE FALLO
            this.$router.push({
              path: "/solicitud/coche/" + this.codigo,
              hash: null,
            });
          }
          setTimeout(() => {
            store.commit("setPreload", false);
          }, 1000);
        })
        .catch(() => {
          // En caso de no coincidir token, redirigir
          store.commit("setPreload", false);
          this.setStep(1);
          this.$router.push({ path: "/home", hash: null });
        });
    }
  },
  watch: {
    windowWidth() {
      const menu = document.querySelector('#navigation_menu');
      menu.style.display = 'block';
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
#ganvam-cero{
  text-align: center; 
  margin-top: 20px!important; 
  width: 80%; 
  margin: 0 auto;
}
#navigation_menu {
  display: block !important;
}
.title {
  width: 100%;
  font-family: "Montserrat";
  font-display: swap;
  font-style: normal;
  font-size: 1.9rem;
  line-height: auto;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.bg {
  // margin-top: 91px;
  background-size: cover;
  background-color: #004680;
  color: #fff;
  height: 14rem;
}
.bg-money {
  // margin-top: 91px;
  background-size: cover;
  background: rgb(255, 165, 0);
  background: linear-gradient(32deg, #ffa500 10%, #fff6e5 10.33%, #fff6e5 90.33%, #ffa500 33.33%);
  color: #004680;
}

.primera-azul {
  font-family: "Montserrat-Bold";
  color: $blue;
  margin-bottom: 0px !important;
}
.cuarta {
  font-family: "Montserrat-Bold";
  font-style: normal;
  line-height: 18px;
  text-align: center;
}
.quinta {
  font-size: 14px;
  color: #555;
}
.orange {
  color: $orange;
  text-decoration: underline;
}
#slogan-footer {
  height: 10rem;
}

.primera-azul {
  font-size: 30px;
  font-family: "Montserrat-Bold";
  color: $blue;
}
.cuarta {
  font-family: "Montserrat-Bold";
  font-style: normal;
}
.quinta {
  font-size: 14px;
  color: #555;
}
.sexta {
  font-family: "Montserrat";
  text-align: center;
  color: $blue;

  font-weight: bold;
}
.orange {
  color: $orange;
  text-decoration: underline;
}
#slogan-footer {
  height: 10rem;
}

// Resposive para pantallas a partir de tamaño medio a pequeño

@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";

@include media-breakpoint-only(xs) {
  .bg-money {
    height: 72px;
  }
  .primera-azul {
    font-size: 35px;
  }
  #onlyDesktop {
    display: none;
  }
  .title {
    padding: 0;
    line-height: 1.7rem;
    font-size: 1.2rem;
  }
  .bg {
    margin-top: 67px;
    height: auto;
  }
  .title-1 {
    font-size: 16px;
  }
  h2.soli-aceptada {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
  .container {
    padding-top: 0.5rem;
  }
  .cuarta {
    font-size: 18px;
  }
  .quinta {
    font-size: 12px;
    line-height: 10px;
  }
  .sexta {
    margin-top: 50px;
    font-weight: 500;
    font-size: 17px;
    line-height: 10px;
    /* identical to box height */
    text-align: center;
    color: #004680;
  }
  h4.preaprobado {
    font-size: 22px;
  }
}

@include media-breakpoint-only(sm) {
  .bg-money {
    height: 122px;
  }
  .primera-azul {
    font-size: 42px;
  }
  #onlyDesktop {
    display: none;
  }
  .title {
    padding: 0;
    line-height: 1.7rem;
    font-size: 1.2rem;
  }
  .bg {
    margin-top: 100px;
    height: auto;
  }
  .title-1 {
    font-size: 16px;
  }
  h2.soli-aceptada {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
  .container {
    padding-top: 0.5rem;
  }
  .cuarta {
    font-size: 16px;
  }
  .sexta {
    font-weight: 600;
    font-size: 24px;
  }
}

@include media-breakpoint-between(md, lg) {
  .bg-money {
    height: 100px;
  }
  .primera-azul {
    font-size: 42px;
  }
  #onlyDesktop {
    display: none;
  }
  .sexta {
    font-weight: 600;
    font-size: 24px;
  }
  .cuarta {
    font-size: 16px;
  }
  .bg {
    height: auto !important;
  }
  .title {
    line-height: 1.7rem;
    font-size: 21px;
  }
  h2 {
    font-size: 1.8rem;
  }
  .title-1 {
    line-height: 1.6rem;
  }
}

@include media-breakpoint-between(xl, xxl) {
  .bg-money {
    height: 122px;
  }
  .primera-azul {
    font-size: 64px;
  }
  .cuarta {
    font-size: 20px;
  }
  .sexta {
    font-weight: 600;
    font-size: 24px;
  }
  .bg {
    height: auto !important;
  }
  .title-1 {
    line-height: 2rem;
    padding-top: 8px;
  }
}
</style>
