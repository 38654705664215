<template>
  <div>
    <fieldset :class="{ cambio_paso: transicion }">
      <div id="cards_row_form" class="row justify-content-center pt-4-l pt-4-xl">
        <div class="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10">
          <div class="row justify-content-center">
            <p class="text-center slogan2">¿Tienes Ingresos?</p>
            <p v-if="api_errors.ingresos" class="error">Debe marcar una opción...</p>
          </div>
          <div class="row justify-content-center mt-3">
            <div class="col-12 align-self-center">
              <div class="card-columns">
                <div class="card" :class="{ active: getIngresos() == 'si' }" @click="setIngresos('si')">
                  <div class="card-body">
                    <div class="d-flex justify-content-center align-items-center">
                      <span class="font-weight-bold">Sí</span>
                    </div>
                  </div>
                </div>
                <div class="card" :class="{ active: getIngresos() == 'no' }" @click="setIngresos('no')">
                  <div class="card-body">
                    <div class="d-flex justify-content-center align-items-center">
                      <span class="font-weight-bold">No</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 pt-4" id="step1">
              <label class="checkbox_label" for="aceptoAll">
                <input type="checkbox" id="aceptoAll" class="checkbox" name="legal_advice" v-model="agreement" @click="marcarCheckBoxs($event)" />
                <span class="checkbox_text pl-2 font-weight-bold">Seleccionar / deseleccionar todo</span>
              </label>
            </div>
            <div class="col-12 text-center pt-4 pb-3" id="step1">
              <button type="button" class="btn-orange" @click="comprobarDatos()">Solicitar préstamo</button>
              <p class="mini-slogan text-center mt-2 m-0">Esta solicitud es gratuita y sin compromiso</p>
            </div>
            <div class="col-12 pt-4" id="step1">
              <label class="checkbox_label" for="acepto1">
                <input type="checkbox" id="acepto1" class="checkbox" name="legal_advice" v-model="formulario.acepto1" @click="marcarCheckBoxs($event)" />
                <span class="checkbox_text pl-2">He leído el <strong>Aviso legal</strong> y acepto la <strong>Política de Privacidad</strong>.</span>
              </label>
              <p v-if="errors.check1" class="error">Debe marcar este campo...</p>
            </div>
            <div class="col-12 pt-4 mb-3" id="step1">
              <label class="checkbox_label" for="acepto2">
                <input type="checkbox" id="acepto2" class="checkbox" name="legal_advice" v-model="formulario.acepto2" @click="marcarCheckBoxs($event)" />
                <span class="checkbox_text pl-2">Acepto recibir la información que <strong>IBANCAR WORLD S.L.</strong> y el resto de socios terceros <span v-if="leerMas">indicados en la Política de Privacidad consideren oportuno enviarme por correo electrónico y por whatsapp. (Es posible darse de baja en cualquier momento).</span></span>
              </label>
              <small @click="leerMas = !leerMas" class="verMas pb-1" :class="leerMas ? 'verMenos' : ''">{{ leerMas ? "Leer menos" : "Leer mas" }}</small>
              <p v-if="errors.check2" class="error">Debe marcar este campo...</p>
            </div>
          </div>
        </div>
      </div>
    </fieldset>
    <div id="volver_row_form" class="row justify-content-around pt-4">
        <div class="col-10 ">
            <p class="text-left"><a class="volver_circle"  @click="handleClick" >&lt;</a></p>
        </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import store from "@/store";

window.dataLayer = window.dataLayer || []; //LLAMADA GOOGLE TAG

function gtag() {
  window.dataLayer.push(arguments);
}
function gtag_report_conversion() {
  var callback = function () {};
  gtag("event", "conversion", {
    send_to: "AW-873880947/Pb39CNC13nAQ87rZoAM",
    event_callback: callback,
  });
  return false;
}

function gtag_report_conversion_ko() {
  var callback = function () {};
  gtag("event", "conversion", {
    send_to: 'AW-873880947/CLQaCLPFzPAYEPO62aAD',
    value: 1.0,
    currency: 'EUR',
    event_callback: callback,
  });
  return false;
}

export default {
  name: "Step3API",
  data: function () {
    return {
      marcas: [],
      modelos: [],
      agreement: null,
      years: [],
      leerMas: false,
      errors: {
        marca: false,
        modelo: false,
        fecha: false,
        potencia: false,
        nolose: false,
        check1: false,
        check2: false,
      },
    };
  },
  created: function () {
    window.dataLayer = window.dataLayer || []; //LLAMADA GOOGLE TAG
    window.dataLayer.push({
      event: "ingresos_api",
      idAfiliado: this.solicitud.web,
    });
  },
  store,
  computed: mapState({
    step: (state) => state.formulario.step,
    api_token: (state) => state.api_token,
    formulario: (state) => state.formulario,
    data_matricula: (state) => state.formulario.data_matricula,
    solicitud: (state) => state.solicitud,
    token_enlace: (state) => state.token_enlace,
    stepsAPI: (state) => state.stepsAPI,
    api_errors: (state) => state.api_errors,
    api_url: (state) => state.api_url
  }),
  methods: {
    getYear(fecha) {
      let date = new Date(fecha);
      return date.getFullYear();
    },
    registrarSolicitud() {
      gtag("config", "AW-873880947");
      // window.scrollTo(0, document.getElementById("formulario").scrollTop);
      let url_origen = localStorage.getItem("url_origen") ? localStorage.getItem("url_origen") : this.formulario.url_origen;
      this.cancelTokenSource = axios.CancelToken.source();

      let data = {
        cancelToken: this.cancelTokenSource.token,
        city: this.formulario.ubicacion,
        car_km: this.formulario.kms,
        car_year_registration: this.getYear(this.formulario.data_matricula.fecha_matriculacion),
        car_number_plate: this.formulario.matricula.join(""),
        ingresos: this.formulario.titular,
        financiacion: this.formulario.financiacion,
        titular: this.formulario.titular,
        importe: this.formulario.dinero,
        legal_advice: this.formulario.acepto1,
        lopd: this.formulario.acepto2,
        matricula: this.solicitud.coche_matricula,
        coche_ganvam: this.solicitud.coche_ganvam,
        // Datos de afiliados
        url_origen: url_origen,
        // Otros
        data_matricula: true,
      };
      let idSolicitud = this.solicitud.id;
      let tokenEnlace = this.token_enlace;
      this.timeout = setTimeout(() => {
        if (this.formulario.loading == true) {
          store.commit("setBarraColor2", "azul");
          store.commit("setCheckColor3", "azul-3");
          this.cancelTokenSource.cancel();

          data.data_matricula = false;
          axios
            .post(this.api_url + "update/formulario/solicitud/detalles/" + idSolicitud, data, {headers:{'Access-Control-Allow-Origin': '*','Content-Type': '*'}})
            .then((data) => {
              this.formulario.loading = false;
              let solicitud = data.data;
              store.commit("setTokenEnlace", tokenEnlace);
              store.commit("setIdSolicitud", idSolicitud);
              // store.commit("setSolicitud", solicitud);

              if (solicitud.numeroestado >= 9000) {
                store.commit("setStep", 10);
                this.$router.push({
                  path: "/solicitud/denegada/" + tokenEnlace,
                  hash: null,
                });
              } else {
                console.log("refresh");
                window.location.reload();
              }
            })
            .catch(() => {
              this.formulario.loading = false;
              store.commit("setStep", 3);
            });
        }
      }, 15000);

      this.formulario.loading = true;
      axios
        .post(this.api_url + "update/formulario/solicitud/detalles/" + idSolicitud, data)
        .then((data) => {
          clearTimeout(this.timeout);
          this.formulario.loading = false;
          let solicitud = data.data;
          store.commit("setTokenEnlace", tokenEnlace);
          store.commit("setIdSolicitud", idSolicitud);
          // store.commit("setSolicitud", solicitud);

          if (solicitud.numeroestado >= 9000) {
            store.commit("setStep", 10);
            this.$router.push({
              path: "/solicitud/denegada/" + tokenEnlace,
              hash: null,
            });
            return gtag_report_conversion_ko();
          } else {
            if (solicitud.coche_marca === "") {
              store.commit("setStep", 3);
            } else {
              console.log("refresh");
              window.location.reload();
            }
          }
        })
        .catch(() => {
          this.formulario.loading = false;
          store.commit("setStep", 5);
        });

      return gtag_report_conversion();
    },
    comprobarDatos() {
      const checks = this.comprobarChecks();
      const financiacion = this.comprobarFinanciacion();
      const titular = this.comprobarTitular();
      const ingresos = this.comprobarIngresos();
      if ( checks && financiacion && titular && ingresos) {
        this.registrarSolicitud();
      }
    },

    setIngresos(respuesta) {
      store.commit("setIngresos", respuesta);
    },
    getIngresos() {
      return this.formulario.ingresos;
    },
    comprobarChecks() {
      // COMPROBAMOS SI ESTA MARCADO LOS CHECKS
      if(this.formulario.acepto1 == false && this.formulario.acepto2 == true) {
        this.errors.check1 = true;
        this.errors.check2 = false;
        return false;
      }else if(this.formulario.acepto1 == true && this.formulario.acepto2 == false){
        this.errors.check1 = false;
        this.errors.check2 = true;
        return false;
      }else if(this.formulario.acepto1 == false && this.formulario.acepto2 == false) {
        this.errors.check1 = true;
        this.errors.check2 = true;
        return false;
      }
      this.errors.check1 = false;
      this.errors.check2 = false;
      return true;
    },
    comprobarIngresos() {
      // COMPROBAMOS SI ESTA MARCADO EL CAMPO INGRESOS
      if (this.formulario.ingresos == "no") {
        return true
      }else if(this.formulario.ingresos == "") {
        store.commit("setApiErrorIngresos",true);
        return false;
      }
      store.commit("setApiErrorIngresos",false);
      return true;
    },
    comprobarFinanciacion() {
      //COMPROBAMOS SI ESTA MARCADO EL CAMPO FINANCIACION
      if (this.formulario.financiacion == '0') {
        store.commit("setApiErrorFinanciado",true);
        return true;
      }else if (this.formulario.financiacion == "si") {
        return true;
      }
      store.commit("setApiErrorFinanciado",false);
      return true;
    },
    comprobarTitular() {
      //COMPROBAMOS SI ESTA MARCADO EL CAMPO TITULAR
      if (this.formulario.titular == "no") {
        return true;
      }else if(this.formulario.titular == 0) {
        store.commit("setApiErrorTitular",true);
        return true;
      }
      store.commit("setApiErrorTitular",false);
      return true;
    },
    marcarCheckBoxs(event) {
      //Marcamos las checkboxes del form
      const checkbox_name = event.target.id;
      let aceptoAll = document.getElementById("aceptoAll");
      let acepto1 = document.getElementById("acepto1");
      let acepto2 = document.getElementById("acepto2");
      switch (checkbox_name) {
        case "aceptoAll": //CASILLA SELECCIONAR TODO
          if (aceptoAll.checked) {
            acepto1.checked = true;
            acepto2.checked = true;
            store.commit("setChecksAcepto", true);
          } else {
            acepto1.checked = false;
            acepto2.checked = false;
            store.commit("setChecksAcepto", false);
          }
          break;
        case "acepto1":
          if (acepto1.checked & acepto2.checked) {
            aceptoAll.checked = true;
            store.commit("setCheckAcepto1", true);
          } else {
            aceptoAll.checked = false;
            store.commit("setCheckAcepto1", false);
          }
          break;
        case "acepto2":
          if (acepto1.checked & acepto2.checked) {
            aceptoAll.checked = true;
            store.commit("setCheckAcepto2", true);
          } else {
            aceptoAll.checked = false;
            store.commit("setCheckAcepto2", false);
          }
          break;
      }
    },
    handleClick() {
      // Lógica para cambiar el valor de stepsAPI
      this.$store.commit('setStepAPI', 2);
    }
  },
  mounted: function () {
    //marcamos las pelotitas que indican los pasos
    //Set color de las bolitas que indican el cambio de paso
    store.commit("setBarraColor1", "azul");
    store.commit("setCheckColor2", "azul-2");
    store.commit("setBarraColor2", "azul");
    store.commit("setCheckColor3", "azul-3");
  },
};
</script>

<style scoped lang="scss">
@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";
.slogan2 {
  font-family: "Montserrat-Bold" !important;
}


#image_formulario {
  background-repeat: no-repeat;
  background-size: cover;
}

#cards_row_form {
  margin-top: 20px;
}

.card {
  background-color: white;
  border: 2px solid rgba(196, 196, 196, 0.4);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  &:hover {
    background-color: #004680;
    color: white;
    border: 2px solid rgba(196, 196, 196, 0.4);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    cursor: pointer;
  }
  &:active {
    background-color: #004680;
    color: white;
    border: 2px solid rgba(196, 196, 196, 0.4);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
  }
}
.active {
  background-color: #004680;
  color: white;
  border: 2px solid rgba(196, 196, 196, 0.4);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}
.volver_circle {
      height: 35px;
      width: 35px;
      display: table-cell;
      text-align: center;
      vertical-align: middle;
      border: solid 1px #DADADA;
      border-radius: 50%; /* may require vendor prefixes */
      background: #fff;
    }
</style>

<style lang="scss">
@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";
@include media-breakpoint-only(md) {
  #navigation_menu {
    display: none;
  }
}

@include media-breakpoint-only(sm) {
  #navigation_menu {
    display: none;
  }
  #volver_row_form{
    display: none!important;
  }
}
@include media-breakpoint-only(xs) {
  #navigation_menu {
    display: none;
  }
  #volver_row_form{
    display: none!important;
  }
}
.mini-slogan {
  font-size: 14px;
}
fieldset {
  margin: 5vh 15% 5vh;
  padding: 2%;
  border: 1px #b3dcff solid;
}
.autocompletar:deep() .simple-typeahead > input[data-v-f81ca714] {
  width: 100%;
  padding: 10px 20px;
  height: 48px;
  line-height: 22px;
  font-size: 18px;
  border-radius: 6px;
  background: white;
  border: solid 1px #c4c4c4;
  opacity: 0.6;
}

#step1 {
  height: 100%;
  .select,
  .ibn_input {
    width: 100%;
    padding: 10px 20px;
    height: 48px;
    line-height: 22px;
    font-size: 18px;
    border-radius: 6px;
    background: white;
    border: solid 1px #c4c4c4;
    opacity: 0.6;
  }
  .checkbox_label {
    display: inline;
    align-items: center;
    color: #004680;
    font-size: small;
  }
  .checkbox {
    width: 1.6em;
    height: 1.6em;
    background: #fff;
    border-radius: 50%;
    vertical-align: middle;
    border: 2px solid #004680;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    cursor: pointer;
  }
  .checkbox:checked {
    background-image: url("../../assets/img/checked_icon.svg");
    background-repeat: no-repeat;
    background-position: center center;
  }
  .contenedor {
    position: relative;
    text-align: center;
    color: white;
  }
  .centered {
    position: absolute;
    top: 82%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #004680;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
  }
  #image_formulario {
    background-repeat: no-repeat;
    background-size: cover;
  }
  #cards_row_form {
    margin-top: 20px;
  }
  .card {
    background-color: white;
    border: none;
  }
  .verMas {
    color: #ffa500;
    font-weight: 600;
    cursor: pointer;
  }
  .feature {
    font-family: "Montserrat-Bold";
    font-size: 14px;
  }
}

@include media-breakpoint-only(xs) {
  .btn-orange {
    font-size: 19px !important;
  }
  .mini-slogan {
    font-weight: 400;
    font-size: 12px;
  }
  #cards_row_form {
    margin-top: 0px;
  }
  fieldset {
    margin: 0;
    padding: 2%;
    border: none;
  }
  #cards_row_form {
    .slogan2 {
      font-size: 18px;
      font-family: 'Montserrat-Bold';
    }
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 375px;
    }

    #first_row {
      height: 85px;
      max-height: 220px;
      background-color: #b3dcff;
    }

    .card-columns {
      column-count: 2;
      max-width: 100%;
      width: 100%;
    }
    .card {
      width: 100%;
      height: auto;
      span {
        font-size: 18px;
      }
    }
    .card:hover {
      width: 100%;
      height: auto;
      span {
        font-size: 18px;
      }
    }
  }
}
@include media-breakpoint-only(sm) {
  .btn-orange {
    font-size: 19px !important;
  }
  .mini-slogan {
    font-weight: 400;
    font-size: 14px;
  }
  fieldset {
    margin: 0;
    padding: 2%;
    border: none;
  }
  #step1 {
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 375px;
    }

    #first_row {
      height: 86px;
      max-height: 220px;
      background-color: #b3dcff;
    }

    .card-columns {
      column-count: 1;
      max-width: 100%;
      width: 100%;
    }
    .card {
      width: 100%;
      height: auto;
    }
    .card:hover {
      width: 100%;
      height: auto;
    }
  }
  .card-columns {
      column-count: 2;
      max-width: 100%;
      width: 100%;
    }
    .card {
      width: 100%;
      height: auto;
      span {
        font-size: 18px;
      }
    }
    .card:hover {
      width: 100%;
      height: auto;
      span {
        font-size: 18px;
      }
    }
}

@include media-breakpoint-only(md) {
  .mini-slogan {
    font-weight: 400;
    font-size: 14px;
  }
  #cards_row_form {
    .slogan2 {
      font-size: 20px;
      font-family: "Montserrat-Bold";
    }
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 325px;
    }
    #first_row {
      min-width: 320px;
      width: 100%;
      height: 97px;
      max-height: 220px;
      background-color: #b3dcff;
      // margin-top: 100px;
    }

    .card-columns {
      column-count: 2;
      max-width: 100%;
      width: 99%;
    }
    .card {
      width: 100%;
      height: auto;
      span {
        font-size: 18px;
      }
    }
    .card:hover {
      width: 100%;
      height: auto;
      span {
        font-size: 18px;
      }
    }
  }
}

@include media-breakpoint-only(lg) {
  .mini-slogan {
    font-weight: 400;
    font-size: 14px;
  }
  #step1 {
    .slogan1 {
      color: #004680;
      font-size: 20px;
      font-weight: 600;
    }
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 375px;
    }
    #first_row {
      min-width: 320px;
      width: 100%;
      height: 97px;
      max-height: 220px;
      background-color: #b3dcff;
      // margin-top: 100px;
    }

    .card-columns {
      column-count: 2;
      max-width: 100%;
      width: 99%;
    }
    .card {
      height: 90px;
      
    }
  }
  .card-columns {
      column-count: 2;
      max-width: 100%;
      width: 99%;
    }
    .card {
      width: 100%;
      height: auto;
      span {
        font-size: 28px;
      }
    }
    .card:hover {
      width: 100%;
      height: auto;
      span {
        font-size: 28px;
      }
    }
}

@include media-breakpoint-only(xl) {
  #cards_row_form {
    .slogan1 {
      color: #004680;
      font-size: 20px;
      font-weight: 600;
    }
    .slogan2 {
      font-size: 22px;
      font-family: "Montserrat-Bold";
    }
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 525px;
    }
    #first_row {
      min-width: 320px;
      width: 100%;
      height: 97px;
      max-height: 220px;
      background-color: #b3dcff;
      // margin-top: 100px;
    }
    .card-columns {
      column-count: 2;
      max-width: 100%;
      width: 100%;
    }
    .card {
      width: 100%;
      height: auto;
      span {
        font-size: 20px;
      }
    }
    .card:hover {
      width: 100%;
      height: auto;
      span {
        font-size: 20px;
      }
    }
  }
}
</style>
