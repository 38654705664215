<template>
  <div id="step1">
    <div id="separator_row_form" class="row justify-content-around">
      <div class="col-10 col-sm-10 col-md-8 col-lg-6">
        <hr />
        <p class="text-center" style="font-family: Montserrat-Bold">Más de 6.000 préstamos concedidos</p>
      </div>
    </div>
    <div id="images_row_form" class="row justify-content-center">
      <div class="col-10 col-sm-7 col-md-8 col-lg-7 col-xl-6">
        <div class="card-columns">
          <div class="card">
            <div class="row align-items-center">
              <div class="col-3">
                <img src="../../assets/img/image_dinero.svg" alt="dinero" />
              </div>
              <div class="col-9">
                <span class="text-center feature">Dinero en cuenta en el mismo día</span>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="row align-items-center">
              <div class="col-3">
                <img src="../../assets/img/image_casa.svg" alt="casa" />
              </div>
              <div class="col-9">
                <span class="text-center feature">100% online sin salir de casa</span>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="row align-items-center">
              <div class="col-3">
                <img src="../../assets/img/image_documentacion.svg" alt="documentacion" />
              </div>
              <div class="col-9">
                <span class="text-center feature">Cancela el préstamo cuando quieras</span>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="row justify-content-start align-items-center">
              <div class="col-3">
                <img src="../../assets/img/image_tarta.svg" alt="tarta" />
              </div>
              <div class="col-8 col-sm-9">
                <span class="text-center feature">Sin alquiler y amortiza desde la primera cuota</span>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="d-flex justify-content-start align-items-center">
              <div class="col-3">
                <img src="../../assets/img/image_calendario.svg" alt="calendario" />
              </div>
              <div class="col-9">
                <span class="text-center feature">Hasta 2 carencias al año</span>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="d-flex justify-content-start align-items-center">
              <div class="col-3">
                <img src="../../assets/img/image_titularidad.svg" alt="titularidad" />
              </div>
              <div class="col-9">
                <span class="text-center feature">Sin cambio de titularidad</span>
              </div>
            </div>
          </div>
        </div>
       
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "cardsFormulario",
};
</script>
<style lang="scss">
@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";
@include media-breakpoint-only(md) {
  #navigation_menu {
    display: none;
  }
}

@include media-breakpoint-only(sm) {
  #navigation_menu {
    display: none;
  }
}
@include media-breakpoint-only(xs) {
  #navigation_menu {
    display: none;
  }
}
</style>
<style scoped lang="scss">
@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";

fieldset {
  margin: 5vh 15% 5vh 15%;
  padding: 2%;
  border: 1px #b3dcff solid;
}
.autocompletar:deep() .simple-typeahead > input[data-v-f81ca714] {
  width: 100%;
  padding: 10px 20px;
  height: 48px;
  line-height: 22px;
  font-size: 18px;
  border-radius: 6px;
  background: white;
  border: solid 1px #c4c4c4;
  opacity: 0.6;
}

#step1 {
  height: 100%;
  .select,
  .ibn_input {
    width: 100%;
    padding: 10px 20px;
    height: 48px;
    line-height: 22px;
    font-size: 18px;
    border-radius: 6px;
    background: white;
    border: solid 1px #c4c4c4;
    opacity: 0.6;
  }
  .checkbox_label {
    display: inline;
    align-items: center;
    color: #004680;
    font-size: small;
  }
  .checkbox {
    width: 1.6em;
    height: 1.6em;
    background: #fff;
    border-radius: 50%;
    vertical-align: middle;
    border: 2px solid #004680;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    cursor: pointer;
  }
  .checkbox:checked {
    background-image: url("../../assets/img/checked_icon.svg");
    background-repeat: no-repeat;
    background-position: center center;
  }
  .contenedor {
    position: relative;
    text-align: center;
    color: white;
  }
  .centered {
    position: absolute;
    top: 82%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #004680;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
  }
  #image_formulario {
    background-repeat: no-repeat;
    background-size: cover;
  }
  #cards_row_form {
    margin-top: 20px;
  }
  .card {
    background-color: white;
    border: none;
  }
  .verMas {
    color: #ffa500;
    font-weight: 600;
    cursor: pointer;
  }
  .feature {
    font-family: "Montserrat-Bold";
    font-size: 14px;
  }
}

@include media-breakpoint-only(xs) {
  #cards_row_form {
    margin-top: 0px !important;
  }
  fieldset {
    margin: 0;
    padding: 2%;
    border: none;
  }
  #step1 {
    #image_formulario {
      width: 100%;
      height: 375px;
    }

    #first_row {
      height: 85px;
      max-height: 220px;
      background-color: #b3dcff;
    }

    .card-columns {
      column-count: 1;
      max-width: 100%;
      width: 100%;
    }
    .card {
      width: 100%;
      height: auto;
    }
    .card:hover {
      width: 100%;
      height: auto;
    }
  }
}
@include media-breakpoint-only(sm) {
  fieldset {
    margin: 0;
    padding: 2%;
    border: none;
  }
  #step1 {
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 375px;
    }

    #first_row {
      height: 86px;
      max-height: 220px;
      background-color: #b3dcff;
    }

    .card-columns {
      column-count: 1;
      max-width: 100%;
      width: 100%;
    }
    .card {
      width: 100%;
      height: auto;
    }
    .card:hover {
      width: 100%;
      height: auto;
    }
  }
}

@include media-breakpoint-only(md) {
  #step1 {
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 325px;
    }
    #first_row {
      min-width: 320px;
      width: 100%;
      height: 97px;
      max-height: 220px;
      background-color: #b3dcff;
      // margin-top: 100px;
    }

    .card-columns {
      column-count: 2;
      max-width: 100%;
      width: 99%;
    }
    .card {
      height: 90px;
    }
  }
}

@include media-breakpoint-only(lg) {
  #step1 {
    margin-top: 20px;

    .slogan1 {
      color: #004680;
      font-size: 20px;
      font-weight: 600;
    }
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 375px;
    }
    #first_row {
      min-width: 320px;
      width: 100%;
      height: 97px;
      max-height: 220px;
      background-color: #b3dcff;
    }

    .card-columns {
      column-count: 2;
      max-width: 100%;
      width: 99%;
    }
    .card {
      height: 90px;
    }
  }
}

@include media-breakpoint-only(xl) {
  #step1 {
    margin-top: 20px;

    .slogan1 {
      color: #004680;
      font-size: 20px;
      font-weight: 600;
    }
    #image_formulario {
      width: 100%;
      height: 525px;
    }
    #first_row {
      min-width: 320px;
      width: 100%;
      height: 97px;
      max-height: 220px;
      background-color: #b3dcff;
    }
    .card-columns {
      column-count: 2;
      max-width: 100%;
      width: 99%;
    }
    .card {
      height: 90px;
    }
  }
}
</style>
