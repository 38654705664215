<template>
  <fieldset :class="{ cambio_paso: transicion }">
    <div id="cards_row_form" class="row justify-content-center pt-4-xl pt-4-l">
      <div class="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10">
        <div class="row justify-content-center">
          <p class="text-center slogan2">¿Eres titular del coche?</p>
          <p v-if="api_errors.titular" class="error">Debe marcar una opción...</p>
        </div>
        <div class="row justify-content-center mt-3">
          <div class="col-12 align-self-center">
            <div class="card-columns">
              <div class="card" :class="{ active: getTitular() == 'si' }" @click="setTitular('si')">
                <div class="card-body">
                  <div class="d-flex justify-content-center align-items-center">
                    <span class="font-weight-bold">Sí</span>
                  </div>
                </div>
              </div>
              <div class="card" :class="{ active: getTitular() == 'no' }" @click="setTitular('no')">
                <div class="card-body">
                  <div class="d-flex justify-content-center align-items-center">
                    <span class="font-weight-bold">No</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </fieldset>
  <div id="volver_row_form" class="row justify-content-around pt-4">
    <div class="col-10 ">
        <p class="text-left"><a class="volver_circle"  @click="handleClick" >&lt;</a></p>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import store from "@/store";

export default {
  name: "Step2API",
  data: function () {
    return {
      marcas: [],
      modelos: [],
      years: [],
      errors: {
        marca: false,
        modelo: false,
        fecha: false,
        potencia: false,
        nolose: false,
      },
    };
  },
  created: function () {
    window.dataLayer = window.dataLayer || []; //LLAMADA GOOGLE TAG

    window.dataLayer.push({
      event: "titularidad_api",
      idAfiliado: this.solicitud.web,
    });
  },
  store,
  computed: mapState({
    step: (state) => state.formulario.step,
    api_token: (state) => state.api_token,
    formulario: (state) => state.formulario,
    data_matricula: (state) => state.formulario.data_matricula,
    solicitud: (state) => state.solicitud,
    token_enlace: (state) => state.token_enlace,
    stepsAPI: (state) => state.stepsAPI,
    api_errors: (state) => state.api_errors
  }),
  methods: {
    getTitular() {
      return this.formulario.titular;
    },
    setTitular(respuesta) {
      store.commit("setTitular", respuesta);
      this.formulario.titular = respuesta;
      this.transicion = screen.width > 755;
      setTimeout(() => {
        store.commit("setStepAPI", 3);
      }, 400);
    },
    handleClick() {
      // Lógica para cambiar el valor de stepsAPI
      this.$store.commit('setStepAPI', 1);
    }
  },
  watch: {
    "formulario.data_matricula.marca": function () {
      this.formulario.data_matricula.modelo = "";
      this.getModelos();
    },
  },
  mounted: function () {
    //Set color de las bolitas que indican el cambio de paso
    store.commit("setBarraColor1", "azul");
    store.commit("setCheckColor2", "azul-2");
    store.commit("setBarraColor2", "blanco");
    store.commit("setCheckColor3", "blanco");
  },
};
</script>

<style scoped lang="scss">
@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";

.volver_circle {
      height: 35px;
      width: 35px;
      display: table-cell;
      text-align: center;
      vertical-align: middle;
      border: solid 1px #DADADA;
      border-radius: 50%; /* may require vendor prefixes */
      background: #fff;
    }
.slogan2 {
  font-family: "Montserrat-Bold" !important;
}

#image_formulario {
  background-repeat: no-repeat;
  background-size: cover;
}

#cards_row_form {
  margin-top: 20px;
}

.card {
  background-color: white;
  border: 2px solid rgba(196, 196, 196, 0.4);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  &:hover {
    background-color: #004680;
    color: white;
    border: 2px solid rgba(196, 196, 196, 0.4);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    cursor: pointer;
  }
  &:active {
    background-color: #004680;
    color: white;
    border: 2px solid rgba(196, 196, 196, 0.4);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
  }
}
.active {
  background-color: #004680;
  color: white;
  border: 2px solid rgba(196, 196, 196, 0.4);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

@include media-breakpoint-only(xs) {
  #cards_row_form {
    margin-top: 0px;
  }
  #cards_row_form {
    .slogan1 {
      color: #004680;
      font-size: 16px;
      font-weight: 600;
    }
    .slogan2 {
      font-size: 18px;
      font-family: "Montserrat-Bold";
    }
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 375px;
    }

    #first_row {
      height: 85px;
      max-height: 220px;
      background-color: #b3dcff;
    }

    .card-columns {
      column-count: 2;
      max-width: 100%;
      width: 100%;
    }
    .card {
      width: 100%;
      height: auto;
      span {
        font-size: 18px;
      }
    }
    .card:hover {
      width: 100%;
      height: auto;
      span {
        font-size: 18px;
      }
    }
  }
  #volver_row_form{
    display: none;
  }
}

@include media-breakpoint-only(sm) {
  #step1 {
    .slogan1 {
      color: #004680;
      font-size: 16px;
      font-weight: 600;
    }
    .slogan2 {
      font-size: 18px;
      font-family: "Montserrat-Bold";
    }
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 375px;
    }

    #first_row {
      height: 86px;
      max-height: 220px;
      background-color: #b3dcff;
    }

    .card-columns {
      column-count: 2;
      max-width: 100%;
      width: 100%;
    }
    .card {
      width: 100%;
      height: auto;
      span {
        font-size: 28px;
      }
    }
    .card:hover {
      width: 100%;
      height: auto;
      span {
        font-size: 28px;
      }
    }
    #volver_row_form{
    display: none;
  }
  }
}

@include media-breakpoint-only(md) {
  #cards_row_form {
    .slogan1 {
      color: #004680;
      font-size: 16px;
      font-weight: 600;
    }
    .slogan2 {
      font-size: 20px;
      font-family: "Montserrat-Bold";
    }
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 325px;
    }
    #first_row {
      min-width: 320px;
      width: 100%;
      height: 97px;
      max-height: 220px;
      background-color: #b3dcff;
      // margin-top: 100px;
    }
    .card-columns {
      column-count: 2;
      max-width: 100%;
      width: 99%;
    }
    .card {
      width: 100%;
      height: auto;
      span {
        font-size: 18px;
      }
    }
    .card:hover {
      width: 100%;
      height: auto;
      span {
        font-size: 18px;
      }
    }
  }
}

@include media-breakpoint-only(lg) {
  fieldset {
    margin: 5vh 15% 5vh 15%;
    padding: 2%;

    border: 1px #b3dcff solid;
  }
  #step1 {
    margin-top: 80px;
    .slogan1 {
      color: #004680;
      font-size: 20px;
      font-weight: 600;
    }
    .slogan2 {
      font-size: 22px;
      font-family: "Montserrat-Bold";
    }
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 375px;
    }
    #first_row {
      min-width: 320px;
      width: 100%;
      height: 97px;
      max-height: 220px;
      background-color: #b3dcff;
      // margin-top: 100px;
    }
    .card-columns {
      column-count: 2;
      max-width: 100%;
      width: 99%;
    }
    .card {
      width: 100%;
      height: auto;
      span {
        font-size: 28px;
      }
    }
    .card:hover {
      width: 100%;
      height: auto;
      span {
        font-size: 28px;
      }
    }
  }
}

@include media-breakpoint-only(xl) {
  fieldset {
    margin: 5vh 15% 5vh 15%;
    padding: 2% 2% 8% 2%;

    border: 1px #b3dcff solid;
  }
  #step1 {
    margin-top: 80px;
    .slogan1 {
      color: #004680;
      font-size: 20px;
      font-weight: 600;
    }
    .slogan2 {
      font-size: 22px;
      font-family: "Montserrat-Bold";
    }
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 525px;
    }
    #first_row {
      min-width: 320px;
      width: 100%;
      height: 97px;
      max-height: 220px;
      background-color: #b3dcff;
      // margin-top: 100px;
    }
    .card-columns {
      column-count: 2;
      max-width: 100%;
      width: 100%;
    }
    .card {
      width: 100%;
      height: auto;
      span {
        font-size: 20px;
      }
    }
    .card:hover {
      width: 100%;
      height: auto;
      span {
        font-size: 20px;
      }
    }
  }
}
</style>
