<template>
  <fieldset :class="{ cambio_paso: transicion }">
    <div id="cards_row_form" class="row justify-content-center pt-4-l pt-4-xl">
      <div class="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10">
        <div class="row justify-content-center">
          <p class="text-center slogan2">¿Está tu coche pagado?</p>
          <p v-if="api_errors.financiado" class="error">Debe marcar una opción...</p>
        </div>
        <div class="row justify-content-center mt-3">
          <div class="col-12 align-self-center">
            <div class="card-columns">
              <div class="card" :class="{ active: getFinanciacion() == 'no' }" @click="setFinanciacion('no')">
                <div class="card-body">
                  <div class="d-flex justify-content-center align-items-center">
                    <span class="font-weight-bold">Sí</span>
                  </div>
                </div>
              </div>
              <div class="card" :class="{ active: getFinanciacion() == 'si' }" @click="setFinanciacion('si')">
                <div class="card-body">
                  <div class="d-flex justify-content-center align-items-center">
                    <span class="font-weight-bold">No</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </fieldset>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import store from "@/store";

export default {
  name: "Step1API",
  data: function () {
    return {
      marcas: [],
      modelos: [],
      years: [],
      errors: {
        marca: false,
        modelo: false,
        fecha: false,
        potencia: false,
        nolose: false,
      },
    };
  },

  store,
  computed: mapState({
    step: (state) => state.formulario.step,
    api_token: (state) => state.api_token,
    formulario: (state) => state.formulario,
    data_matricula: (state) => state.formulario.data_matricula,
    solicitud: (state) => state.solicitud,
    token_enlace: (state) => state.token_enlace,
    stepsAPI: (state) => state.stepsAPI,
    api_errors: (state) => state.api_errors,
    api_url: (state) => state.api_url
  }),
  methods: {
    setFinanciacion(respuesta) {
      store.commit("setFinanciacion", respuesta);
      this.respuesta = respuesta;
      this.transicion = screen.width > 755;
      setTimeout(() => {
        store.commit("setStepAPI", 2);
      }, 400);
    },
    getFinanciacion: function () {
      return this.formulario.financiacion;
    },
    comprobarPotencia() {
      if (this.formulario.data_matricula.potencia == "") {
        this.errors.potencia = true;
        document.getElementById("potencia").style.border = "1px solid #ff000080";
        document.getElementById("potencia").style.borderLeft = "7px solid #ff000080";
        return false;
      } else if (this.formulario.data_matricula.potencia == true) {
        document.getElementById("potencia").style.border = "";
        document.getElementById("potencia").style.borderLeft = "";
      } else {
        this.errors.potencia = false;
        document.getElementById("potencia").style.border = "1px solid green";
        document.getElementById("potencia").style.borderLeft = "7px solid green";
        return true;
      }
    },
    buttonEnabled() {
      // Validar los datos
      let marca = this.formulario.data_matricula.marca;
      let modelo = this.formulario.data_matricula.modelo;
      let matriculacion = this.formulario.data_matricula.fecha_matriculacion;
      let potencia = this.formulario.data_matricula.potencia;
      if (marca != "" && modelo != "" && matriculacion != "" && (parseInt(potencia) >= 0 || potencia == true)) {
        return false;
      } else {
        return true;
      }
    },

    getMarcas: function () {
      axios.get(this.api_url + "getMarcas").then((resp) => {
        this.marcas = resp.data.data;
      });
    },
    getModelos: function () {
      if (this.formulario.data_matricula.marca) {
        axios.get(this.api_url + "getModelosByMarca/" + this.formulario.data_matricula.marca).then((resp) => {
          this.modelos = resp.data.data;
        });
      }
    },

    nextStep: function () {
      store.commit("setStep", 4);
      window.scrollTo(0, 0);
    },
    backStep: function () {
      store.commit("setStep", 5);
      this.$router.push({
        path: "/formulario/" + store.state.token_enlace,
        hash: null,
      });
    },
    jsUcfirst(string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    },
  },
  watch: {
    "formulario.data_matricula.marca": function () {
      this.formulario.data_matricula.modelo = "";
      this.getModelos();
    },
  },
  mounted: function () {
    //Set color de las bolitas que indican el cambio de paso
    store.commit("setBarraColor1", "blanco");
    store.commit("setCheckColor2", "blanco");
    store.commit("setBarraColor2", "blanco");
    store.commit("setCheckColor3", "blanco");
    this.getMarcas();
    this.getModelos();
  },
  created() {
    window.dataLayer = window.dataLayer || []; //LLAMADA GOOGLE TAG
    window.dataLayer.push({
      event: "coche_pagado_api",
      idAfiliado: this.solicitud.web
    });
  },
};
</script>

<style scoped lang="scss">
@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";

.slogan2 {
  font-family: "Montserrat-Bold" !important;
}
.contenedor {
  position: relative;
  text-align: center;
  color: white;
}
.centered {
  position: absolute;
  top: 82%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #004680;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
}

#cards_row_form {
  margin-top: 20px;
}

.card {
  background-color: white;
  border: 2px solid rgba(196, 196, 196, 0.4);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  &:hover {
    background-color: #004680;
    color: white;
    border: 2px solid rgba(196, 196, 196, 0.4);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    cursor: pointer;
  }
  &:active {
    background-color: #004680;
    color: white;
    border: 2px solid rgba(196, 196, 196, 0.4);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
  }
}
.active {
  background-color: #004680;
  color: white;
  border: 2px solid rgba(196, 196, 196, 0.4);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

@include media-breakpoint-only(xs) {
  #cards_row_form {
    margin-top: 0px;
  }
  #cards_row_form {
    .slogan1 {
      color: #004680;
      font-size: 16px;
      font-weight: 600;
    }
    .slogan2 {
      font-size: 18px;
      font-family: "Montserrat-Bold";
    }
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 375px;
    }

    #first_row {
      height: 85px;
      max-height: 220px;
      background-color: #b3dcff;
    }

    .card-columns {
      column-count: 2;
      max-width: 100%;
      width: 100%;
    }
    .card {
      width: 100%;
      height: auto;
      span {
        font-size: 18px;
      }
    }
    .card:hover {
      width: 100%;
      height: auto;
      span {
        font-size: 18px;
      }
    }
  }
}

@include media-breakpoint-only(sm) {
  #cards_row_form {
    .slogan1 {
      color: #004680;
      font-size: 16px;
      font-weight: 600;
    }
    .slogan2 {
      font-size: 18px;
      font-family: "Montserrat-Bold";
    }
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 375px;
    }

    #first_row {
      height: 86px;
      max-height: 220px;
      background-color: #b3dcff;
    }

    .card-columns {
      column-count: 2;
      max-width: 100%;
      width: 100%;
    }
    .card {
      width: 100%;
      height: auto;
      span {
        font-size: 18px;
      }
    }
    .card:hover {
      width: 100%;
      height: auto;
      span {
        font-size: 18px;
      }
    }
  }
}

@include media-breakpoint-only(md) {
  #cards_row_form {
    .slogan1 {
      color: #004680;
      font-size: 16px;
      font-weight: 600;
    }
    .slogan2 {
      font-size: 20px;
      font-family: "Montserrat-Bold";
    }
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 325px;
    }
    #first_row {
      min-width: 320px;
      width: 100%;
      height: 97px;
      max-height: 220px;
      background-color: #b3dcff;
      // margin-top: 100px;
    }
    .card-columns {
      column-count: 2;
      max-width: 100%;
      width: 99%;
    }
    .card {
      width: 100%;
      height: auto;
      span {
        font-size: 18px;
      }
    }
    .card:hover {
      width: 100%;
      height: auto;
      span {
        font-size: 18px;
      }
    }
  }
}

@include media-breakpoint-only(lg) {
  fieldset {
    margin: 5vh 15% 5vh 15%;
    padding: 2%;

    border: 1px #b3dcff solid;
  }
  #cards_row_form {
    .slogan1 {
      color: #004680;
      font-size: 20px;
      font-weight: 600;
    }
    .slogan2 {
      font-size: 22px;
      font-family: "Montserrat-Bold";
    }
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 375px;
    }
    #first_row {
      min-width: 320px;
      width: 100%;
      height: 97px;
      max-height: 220px;
      background-color: #b3dcff;
      // margin-top: 100px;
    }
    .card-columns {
      column-count: 2;
      max-width: 100%;
      width: 99%;
    }
    .card {
      width: 100%;
      height: auto;
      span {
        font-size: 28px;
      }
    }
    .card:hover {
      width: 100%;
      height: auto;
      span {
        font-size: 28px;
      }
    }
  }
}

@include media-breakpoint-only(xl) {
  fieldset {
    margin: 5vh 15% 5vh 15%;
    padding: 2% 2% 8% 2%;

    border: 1px #b3dcff solid;
  }
  #cards_row_form {
    margin-top: 60px;
    .slogan1 {
      color: #004680;
      font-size: 20px;
      font-weight: 600;
    }
    .slogan2 {
      font-size: 22px;
      font-family: "Montserrat-Bold";
    }
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 525px;
    }
    #first_row {
      min-width: 320px;
      width: 100%;
      height: 97px;
      max-height: 220px;
      background-color: #b3dcff;
      // margin-top: 100px;
    }
    .card-columns {
      column-count: 2;
      max-width: 100%;
      width: 100%;
    }
    .card {
      width: 100%;
      height: auto;
      span {
        font-size: 20px;
      }
    }
    .card:hover {
      width: 100%;
      height: auto;
      span {
        font-size: 20px;
      }
    }
  }
}
</style>
