<template>
  <div class="loader">
    <!--meta v-if='false' content='width=device-width; initial-scale=1.0; maximum-scale=1.0; user-scalable=0;' name='viewport' /-->
    <!--meta v-if='false' name="viewport" content="width=device-width" /-->
    <div class="container p-0">
      <div class="row align-items-center">
        <div class="col text-center">
          <div class="animate_logo logo">
            <img class="" src="@/assets/img/IBLogo.svg" alt="logo" />
            <div class="col">
              <p class="text-center text-white font-weight-bold">Estamos calculando <br />tu préstamo</p>
            </div>
          </div>
          <div class="col ellipse text-center">
            <div class="row justify-content-sm-center animate balls">
              <div class="col">
                <img src="@/assets/img/green_ok.svg" alt="ok" />
                <p class="texto-azul">Seguro</p>
              </div>
              <div class="col">
                <img src="@/assets/img/green_ok.svg" alt="ok" />
                <p class="texto-azul">Transparente</p>
              </div>
              <div class="col">
                <img src="@/assets/img/green_ok.svg" alt="ok" />
                <p class="texto-azul">Rápido</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loading",
  components: {},
};
</script>

<style lang="scss" scoped>
@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";

html,
body {
  margin: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.loader {
  background-image: url("../../assets/img/ellipse.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display:flex;
  align-items:center
}
.texto-azul {
  color: #004680;
  font-family: "Montserrat-Bold";
}
@keyframes animation1 {
  50%,
  75% {
    transform: scale(1.2);
  }
}
.animate_logo {
  animation: animation1 2s ease-out infinite;
}
.animate {
  .col {
    animation: animation1 2s ease-out infinite;
    @for $i from 1 through 5 {
      &:nth-child(#{$i}) {
        background: nth($colors, $i);

        &::before {
          animation-delay: $i * 0.2s;
        }
      }
    }
  }
}

@include media-breakpoint-only(xs) {
    @keyframes animation1 {
  50%,
  75% {
    transform: scale(1.1);
  }
}
 .loader {
    top: -10px;
    right: 0;
    left: 0;
    bottom: 0;
    position: absolute;
    background-position: center center;
    margin: 0px;
    padding: 0px;
  } 
  .ellipse {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .balls {
    margin-bottom: 22%;
  }
  .logo {
    margin-bottom: 75%;
  }
}
@include media-breakpoint-only(sm) {
  .loader {
    height: auto;
    min-width: 576px;
    width: auto;
    max-width: 768px;
    img {
      padding-top: 130px;
    }
  }
  .ellipse {
    margin-top: 80px;
  }
}
@include media-breakpoint-only(md){

  .loader {
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    position: absolute;
    background-position: center center;
  } 
  .ellipse {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0
  }
  .balls {
    margin-bottom: 12%;
  }
  .logo {
    margin-bottom: 50%;
  }
}
@include media-breakpoint-only(lg) {
  .loader {
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    position: absolute;
    background-position: 25% 60%;
  } 
  .ellipse {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0
  }
}
@include media-breakpoint-only(xl) {

  .loader {
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    position: absolute;
    background-position: 25% 65%;
  }
  .ellipse {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .balls {
    margin-left: 17%;
    margin-right: 17%;
    margin-bottom: 2%;
  }
  .logo {
    margin-bottom: 25%;
  }
}
</style>
