<template>
  <div id="refuse">
    <div id="first_row" class="row justify-content-center align-items-center pb-3">
        <h3 class="font-weight-bold">¡Lo sentimos!</h3>
    </div>
    <div class="row justify-content-center align-items-center">
        <img :src="publicPath+'refuse.svg'" alt="refuse" >
    </div>

  <div id="cards_row_form" class="row justify-content-center pt-4">
    <div class="col-8 col-sm-10 col-md-8 col-lg-6 col-xl-6">
        <div class="row justify-content-center">
            <p class="text-center" style="font-size:16px;font-weight: 900;">{{ message }}</p>
        </div>
      <div class="row justify-content-center">
          <p class="text-center" style="font-size:14px;">No podemos continuar, ya que tu vehículo no se puede ofrecer como garantía para el préstamo.</p>
        </div>
    </div>
    <div class="col-12 franja mt-4 mb-2">
      <div class="row justify-content-center align-items-center p-2" >
        <span class="text-center">Otras empresas podrán ayudarte a conseguir tu préstamo.</span>
      </div>
    </div> 
    <div class="col-8 col-sm-10 col-md-8 col-lg-6 col-xl-6">
      <div class="row justify-content-center text-center mt-3">
        <div class="">
            <div class="row justify-content-center">
                <div class="card-columns">
                    <div class="card">
                        <div class="card-body p-0">
                            <div class="row pt-2 pb-3 justify-content-center align-items-center">
                                <img :src="publicPath+'fidea.svg'" alt="refuse" >
                            </div>
                            <div class="row justify-content-start">
                                <ul class="text-left">
                                    <li>Hasta 10.000 € y 60 meses</li>
                                    <li>Rápido y fácil</li>
                                    <li>Bajas tasas de interés</li>
                                </ul>
                            </div>
                            <div class="row justify-content-center mt-2">
                                <button class="btn-orange center">
                                    Continuar
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-body p-0">
                            <div class="row pt-2 pb-3 justify-content-center align-items-center">
                                <img :src="publicPath+'crezu.svg'" alt="refuse" >
                            </div>
                            <div class="row justify-content-start">
                                <ul class="text-left">
                                    <li>Hasta 10.000 €</li>
                                    <li>Con cualquier tipo de historial crediticio</li>
                                    <li>Sin comisión por el servicio</li>
                                </ul>
                            </div>
                            <div class="row justify-content-center mt-2">
                                <button class="btn-orange center">
                                    Continuar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <BotonVolver />
        </div>
    </div>
    </div>
  </div>
</div>
</template>

<script>
import store from "@/store";
import BotonVolver from "./BotonVolver.vue";

export default {
  name: "Refuse",
  data: function () {
    return {
        publicPath: process.env.BASE_URL
    };
  },
  store,
  computed: {
    message() {
        return this.$route.params.refuse_message;
    }
  },
  components: { BotonVolver },
  methods: {
    back() {
        this.$router.go(-1);
    }
  },
  watch: {  },
  mounted: function () {
  },
};
</script>

<style scoped lang="scss">
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

#refuse {
  height: 100%;

  .card {
        background-color: white;
        border: 2px solid #FFA500;
    }

    ul {
        font-size: 12px;
    }

    .btn-orange {
        background-color: #FFD748;
        color: #004680;
    }
    .franja{
        height: auto;
        background-color: rgba(255, 165, 0, 0.1);
        span {
            font-family: 'Montserrat-Bold';
            font-size: 14pt;
        }
    }
}


@include media-breakpoint-only(xs) {
    #refuse {
        #first_row {
        background-color: #fff;
        margin-top: 85px;
        }
        .card-columns {
        column-count: 1;
        max-width: 100%;
        width: 100%;
        }
        .card {
            width: 297px;
            height: 214px;
        }
        .btn-orange {
            width: 225px;
            height: 38px;
            font-size: 16px !important;
        }
  }

}

@include media-breakpoint-between(sm,lg) {
    #refuse {
        #first_row {
        background-color: #fff;
        margin-top: 100px;
        }

        .card-columns {
        column-count: 1;
        max-width: 100%;
        width: 100%;
        }
        .card {
            width: 297px;
            height: 214px;
        }
        .btn-orange {
            width: 225px;
            height: 38px;
            font-size: 16px !important;
        }

    }
}

@include media-breakpoint-only(xl) {
    #refuse {
        #first_row {
        background-color: #fff;
        margin-top: 100px;
        }

        .card-columns {
        column-count: 2;
        max-width: 100%;
        width: 100%;
        }
        .card {
            width: 297px;
            height: 214px;
        }
        .btn-orange {
            width: 225px;
            height: 38px;
            font-size: 16px !important;
        }

    }
}

</style>
